import TablessContainer from "@/components/help-center/custom-tags/TablessContainer.vue";

export const tablessContainer = {
  render: TablessContainer,
  attributes: {
    marginStyles: {
      type: String,
    },
    paddingStyles: {
      type: String,
    },
  },
};
